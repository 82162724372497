import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/functions'
import 'firebase/compat/analytics'
import 'firebase/compat/auth'

let config = {}
console.log(`[APP] App is running in ${process.env.VUE_APP_NODE_ENV} mode`)


if (process.env.VUE_APP_NODE_ENV === 'prod') {
  // Production mode
  config = {
    apiKey: 'AIzaSyBrg31GyrP0dqTtPPb49H3am_5eWHgq8Z0',
    authDomain: 'viscenario-v3-production.firebaseapp.com',
    projectId: 'viscenario-v3-production',
    storageBucket: 'viscenario-v3-production.appspot.com',
    messagingSenderId: '464938354386',
    appId: '1:464938354386:web:a46fd25193198d8369cf0b',
    measurementId: 'G-QJTZDWKR4F'
  }
} else {
  // Development mode
  config = {
    apiKey: 'AIzaSyA2XlWMta8IXGfXYMxi1opsLGX3qioPniI',
    authDomain: 'viscenario-v3-development.firebaseapp.com',
    projectId: 'viscenario-v3-development',
    storageBucket: 'viscenario-v3-development.appspot.com',
    messagingSenderId: '439775167122',
    appId: '1:439775167122:web:69556e9b2428eef0225c4b'
  }
}

// Firebase init goes here
!firebase.apps.length && firebase.initializeApp(config)
if (process.env.VUE_APP_NODE_ENV === 'prod') firebase.analytics()

// Firebase utils
const auth = firebase.auth()
const storage = firebase.storage()
// const functions = firebase.functions()
const functions = firebase.app().functions('europe-west2') // Set the functions region to the correct one
const db = firebase.firestore()

// Set up development modes
if (process.env.VUE_APP_LOCAL_DEVELOPMENT === 'enabled') {
  console.info('IMPORTANT: Running local FUNCTIONS and local DATABASE mode')

  // functions.useEmulator('localhost', 5001) // Single computer development
  // functions.useEmulator('10.0.0.254', 5001) // Local network development @ home
  functions.useEmulator('192.168.50.97', 5001) // Local network development @ work

  // auth.useEmulator('http://localhost:9099') // Single computer development
  // auth.useEmulator('http://10.0.0.254:9099') // Local network development @ home
  auth.useEmulator('http://192.168.50.97:9099') // Local network development @ work

  db.settings({
    // host: '0.0.0.0:8081', // Single computer development
    // host: '10.0.0.254:8081', // Local network development @ home
    host: '192.168.50.97:8081', // Local network development @ work
    ssl: false
  })
}

// Firebase collections (only for snapshots - all others are fetched via API)
const snapsCollection = db.collection('snaps')

export {
  snapsCollection,
  config,
  firebase,
  auth,
  storage,
  functions,
  db
}
